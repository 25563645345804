// Based on https://github.com/storyblok/storyblok-nuxt-2/blob/main/lib/module/plugin.mjs

import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import 'isomorphic-fetch';
import Vue from 'vue';

import { StoryblokVue, useStoryblokApi, useStoryblokBridge, apiPlugin } from '@storyblok/vue-2';
import { Route } from 'vue-router';
import { PageStoryInterface } from '~/types/content';
import { getStoryData } from '~/api/storyblok/stories';

export default defineNuxtPlugin(({ $config }, inject) => {
    Vue.use(StoryblokVue, {
        accessToken: $config.STORYBLOK_ACCESS_TOKEN,
        bridge: true,
        apiOptions: {
            cache: {
                type: $config.NODE_ENV === 'development' ? 'none' : 'memory',
            },
            region: 'us',
        },
        use: [apiPlugin],
    });

    const api = useStoryblokApi();

    /**
     * Loads the story data from the given URL with the given relations, if any, resolved.
     * @param url The Storyblok story path to load, like 'cdn/stories/home'.
     * @param throwNuxtError The Nuxt error function provided to asyncData().
     * @param shouldResolveSecondLevelLinks Whether to resolve second level links
     * @param relationsToResolve An array of '{BlockName}.{fieldName}' strings.
     * @param resolveLinksType The method desired with which Storyblok should resolve links.
     * @param route The route object from nuxt context
     * @returns The story data.
     */
    const loadPage = async (
        url: string,
        throwNuxtError: Function,
        shouldResolveSecondLevelLinks: boolean,
        relationsToResolve: string[] = [],
        resolveLinksType: 'url' | 'story' | 'link' = 'url',
        route = <Route>{},
    ) => {
        const isPreview = Object.hasOwn(route.query, 'preview');
        let story: PageStoryInterface = {} as PageStoryInterface;
        try {
            story = await getStoryData(
                url,
                resolveLinksType,
                relationsToResolve,
                isPreview,
                shouldResolveSecondLevelLinks,
            );
        } catch (e: any) {
            // On an error from API present user nuxt error page
            // https://nuxtjs.org/docs/internals-glossary/context/#error
            throwNuxtError(e);
        }
        return story;
    };

    const fetchSolarCompaniesStory = async (
        throwNuxtError: Function,
        relationsToResolve: string[] = [],
        route = <Route>{},
    ): Promise<PageStoryInterface> =>
        loadPage('/local-data/solar-companies', throwNuxtError, false, relationsToResolve, 'story', route);

    const fetchSolarCostStory = async (
        throwNuxtError: Function,
        relationsToResolve: string[] = [],
        route = <Route>{},
    ): Promise<PageStoryInterface> =>
        loadPage(
            '/local-data/solar-panel-cost/solar-panel-cost-template',
            throwNuxtError,
            true,
            relationsToResolve,
            'story',
            route,
        );

    inject('storyblok', {
        api,
        useStoryblokBridge,
        loadPage,
        fetchSolarCompaniesStory,
        fetchSolarCostStory,
    });
});
