import { StoryblokRichTextInterface } from '~/types/storyblok';
import type { StoryblokComponentInterface } from '~/types/storyblok';
import type { PageBlokInterface, PageStoryInterface } from '~/types/content';

const resolveConditionalMarkup = (rawText: string): string => {
    // Search for "{ variable ? truthy result ~ optional falsey result }"
    const regex = /\{(?<test>.*?)\?(?<trueResult>.*?)(~(?<falseResult>.*?))?\}/g;
    let match: RegExpExecArray | null;
    const matches = [];
    let start: number;
    let end: number;
    let test: string;
    let trueResult: string;
    let falseResult: string;

    // Find all matches
    do {
        match = regex.exec(rawText);
        if (match) {
            start = match.index;
            end = start + (match[0].length || 0);
            test = match.groups?.test.trim() || '';
            trueResult = match.groups?.trueResult || '';
            falseResult = match.groups?.falseResult || '';
            matches.push({ start, end, test, trueResult, falseResult });
        }
    } while (match !== null);

    let testStr: string;

    // Replace conditional with appropriate values
    return matches.reduceRight((replacedText, currentMatch) => {
        testStr = currentMatch.test;
        if (!testStr || ['false', 'undefined', 'null'].includes(testStr.toLowerCase()) || Number(testStr) === 0) {
            return `${replacedText.slice(0, currentMatch.start)}${currentMatch.falseResult}${replacedText.slice(
                currentMatch.end,
            )}`;
        }
        return `${replacedText.slice(0, currentMatch.start)}${currentMatch.trueResult}${replacedText.slice(
            currentMatch.end,
        )}`;
    }, rawText);
};

export const computedReplacement = (props: Object, field: string) => {
    let finalText = field;
    Object.entries(props).forEach(([key, value]) => {
        if (key === 'topSuppliersLinks') {
            // This data placeholder should always be a paragraph by itself;
            // Replacing the full paragraph here avoids hydration errors from incorrectly nested elements.
            finalText = finalText.replaceAll(`<p>{${key}}</p>`, value);
        } else {
            finalText = finalText.replaceAll(`{${key}}`, value);
        }
    });

    return resolveConditionalMarkup(finalText);
};

/**
 * Since empty rich text objects can still contain an array with an empty paragraph, this function
 * provides an easy way to check if there is actual text to render in a rich text, allowing a v-if
 * to not render a containing element (usually with a bottom margin) if it won't end up having any content.
 * @param richText A Storyblok rich text object.
 * @returns {boolean} True if the rich text object contains text, false if it doesn't.
 */
export const doesRichTextContainText = (richText: StoryblokRichTextInterface): boolean => {
    if (!richText) {
        return false;
    }

    if (richText.type === 'text' && richText.text) {
        return true;
    }

    if (richText.content && richText.content.length > 0) {
        return richText.content.reduce((result, item) => result || doesRichTextContainText(item), false);
    }

    return false;
};

/* modified from es-cms-local-data utils/storyblok.ts */
export const findComponents = (
    data: PageBlokInterface | StoryblokComponentInterface[] | StoryblokComponentInterface | undefined,
    componentName: string,
): StoryblokComponentInterface[] => {
    let foundComponents: StoryblokComponentInterface[] = [];
    // Case 1: data is list of objects -> concatenate component lists for each
    if (Array.isArray(data)) {
        data.forEach((item) => {
            const results = findComponents(item, componentName);
            if (results.length > 0) {
                foundComponents = [...foundComponents, ...results];
            }
        });
        return foundComponents;
    }
    // Case 2: data is an object -> return found component, or recurse
    if (typeof data === 'object' && data !== null) {
        // Base case: this object is the component we're collecting
        if (data.component === componentName) {
            return [data as StoryblokComponentInterface];
        }
        // Recursive case: search for & aggregate components in each object property
        // Check for _uid to ensure this is a component and not a resolved relation to another story
        // eslint-disable-next-line no-underscore-dangle
        if (data._uid) {
            Object.values(data).forEach((value) => {
                const results = findComponents(value, componentName);
                if (results.length > 0) {
                    foundComponents = [...foundComponents, ...results]; // results BAD
                }
            });
            return foundComponents;
        }
        return [];
    }
    // Case 3: data is not an object
    return [];
};

/* Returns an array of components of type componentName */
export const findComponentsInPage = (
    response: PageStoryInterface,
    componentName: string,
): StoryblokComponentInterface[] => findComponents(response.content, componentName);

/* eslint-disable */
export const findStoryblokObjectsMatchingTest = (
    data:
        | PageBlokInterface
        | PageStoryInterface
        | StoryblokComponentInterface[]
        | StoryblokComponentInterface
        | undefined,
    test: Function,
    depth: number,
    maxDepth: number,
): any[] => {
    const matchingObjects: any[] = [];
    if (depth === maxDepth) {
        return matchingObjects;
    }
    // if an array, call this function on each item
    if (Array.isArray(data)) {
        return data.reduce(
            (result, item) => [...result, ...findStoryblokObjectsMatchingTest(item, test, depth + 1, maxDepth)],
            matchingObjects,
        );
    }
    if (typeof data === 'object' && data !== null) {
        // if the provided test passes for this object, return the object (in an array)
        if (test(data)) {
            return [data];
        }
        // call this function on each value within this object
        return Object.values(data).reduce(
            (result, item) => [...result, ...findStoryblokObjectsMatchingTest(item, test, depth + 1, maxDepth)],
            matchingObjects,
        );
    }
    return matchingObjects;
};
/* eslint-enable */
